// src/services/api.js

import axios from 'axios';

const REMOTE_API_URL = 'https://api.pixelrenderai.com/filter/';

export const getFilters = async () => {
  try {
    const response = await axios.get(REMOTE_API_URL);
    console.log('getFilters response:', response);
    return response.data.data; // Assuming the array is nested under data.data
  } catch (error) {
    console.error('Error fetching filters:', error);
    throw error;
  }
};

export const getFilterById = async (id) => {
  try {
    const response = await axios.get(`${REMOTE_API_URL}${id}`);
    console.log('getFilterById response:', response);
    return response.data.data; // Assuming the object is nested under data.data
  } catch (error) {
    console.error('Error fetching filter by id:', error);
    throw error;
  }
};

export const createFilter = async (filterData) => {
  try {
    const response = await axios.post(REMOTE_API_URL, filterData);
    console.log('createFilter response:', response);
    return response.data.data; // Assuming the object is nested under data.data
  } catch (error) {
    console.error('Error creating filter:', error);
    throw error;
  }
};

export const updateFilter = async (id, filterData) => {
  try {
    const response = await axios.put(`${REMOTE_API_URL}${id}`, filterData);
    console.log('updateFilter response:', response);
    return response.data.data; // Assuming the object is nested under data.data
  } catch (error) {
    console.error('Error updating filter:', error);
    throw error;
  }
};

export const deleteFilter = async (id) => {
  try {
    const response = await axios.delete(`${REMOTE_API_URL}${id}`);
    console.log('deleteFilter response:', response);
    return response.data; // Assuming the message is returned directly
  } catch (error) {
    console.error('Error deleting filter:', error);
    throw error;
  }
};
