// src/components/shared/FilterForm.js

import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './FilterForm.css';
import { addParameter, getParameters, validateParameters } from './Parameters';

function FilterForm({ filter, handleChange, handleSubmit, parametersRef, error }) {
  const [notification, setNotification] = useState('');
  const navigate = useNavigate();

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification('');
    }, 2000);
  };

  return (
    <div className="filter-form">
      <h1>{filter._id ? 'Edit Filter' : 'Create Filter'}</h1>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Model:</label>
          <input type="text" name="model" value={filter.model} onChange={handleChange} className="large-input" />
        </div>
        <div>
          <label>Version:</label>
          <input type="text" name="version" value={filter.version} onChange={handleChange} className="large-input" />
        </div>
        <div>
          <label>Cover Image URL:</label>
          <input type="text" name="cover_img" value={filter.cover_img} onChange={handleChange} className="large-input" />
        </div>
        <div>
          <label>Prompt Template:</label>
          <textarea name="prompt_template" value={filter.prompt_template} onChange={handleChange} rows="6" className="large-textarea"></textarea>
        </div>
        <div>
          <label>Negative Prompt:</label>
          <textarea name="neg_prompt" value={filter.neg_prompt} onChange={handleChange} rows="6" className="large-textarea"></textarea>
        </div>
        <div>
          <label>Filter:</label>
          <input type="text" name="filter" value={filter.filter} onChange={handleChange} className="large-input" />
        </div>
        <div ref={parametersRef}>
          {/* Parameters will be rendered here */}
        </div>
        <button type="button" onClick={() => addParameter(parametersRef, showNotification)}>Add Parameter</button>
        <div className="button-row">
          <button type="submit" className="primary-button">{filter._id ? 'Update' : 'Create'}</button>
          <button type="button" onClick={() => navigate('/')} className="cancel-button">Cancel</button>
        </div>
      </form>
      {notification && <div className="notification show">{notification}</div>}
    </div>
  );
}

export default FilterForm;
