// src/components/EditFilter/EditFilter.js

import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFilterById, updateFilter } from '../../services/api';
import FilterForm from '../shared/FilterForm';
import { getParameters, validateParameters, updateParameterType, deleteParameter, addParameter } from '../shared/Parameters';
import './EditFilter.css';

function EditFilter() {
  const [filter, setFilter] = useState({
    model: '',
    version: '',
    cover_img: '',
    prompt_template: '',
    neg_prompt: '',
    filter: '',
    parameters: [],
  });
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const parametersRef = useRef(null);

  useEffect(() => {
    fetchFilter();
  }, []);

  const fetchFilter = async () => {
    try {
      const data = await getFilterById(id);
      console.log('Fetched filter data:', data);
      if (data) {
        setFilter({
          ...data,
          parameters: data.parameters || [],
        });
      } else {
        setError('Error: Data is not in expected format');
      }
    } catch (err) {
      setError('Error fetching filter. Please try again.');
      console.error('Error fetching filter:', err);
    }
  };

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!parametersRef.current) {
      setError('No parameters defined.');
      return;
    }
    const parameters = getParameters(parametersRef);
    if (!validateParameters(parameters, filter.prompt_template)) {
      setError('Validation failed. Please check the parameters.');
      return;
    }
    try {
      await updateFilter(id, { ...filter, parameters });
      navigate('/');
    } catch (err) {
      setError('Error updating filter. Please try again.');
      console.error('Error updating filter:', err);
    }
  };

  const showNotification = (message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 2000);
  };

  useEffect(() => {
    if (filter.parameters && filter.parameters.length > 0 && parametersRef.current) {
      parametersRef.current.innerHTML = ''; // Clear existing parameters
      filter.parameters.forEach(param => {
        const paramDiv = document.createElement('div');
        paramDiv.classList.add('parameter');

        paramDiv.innerHTML = `
          <label>Parameter Type:</label>
          <select>
            <option value="textbox">Textbox</option>
            <option value="checkbox">Checkbox</option>
            <option value="slider">Slider</option>
            <option value="dropdown">Dropdown</option>
          </select>
          <div class="parameterInputs"></div>
          <div class="parameter-buttons">
            <button type="button" class="copy-parameter">Copy Title</button>
            <button type="button" class="delete-parameter">Delete Parameter</button>
          </div>
        `;

        paramDiv.querySelector('select').value = param.type;
        paramDiv.querySelector('select').addEventListener('change', () => {
          updateParameterType(paramDiv);
        });

        paramDiv.querySelector('.copy-parameter').addEventListener('click', () => {
          const title = paramDiv.querySelector('.param-title').value;
          if (title) {
            const formattedTitle = title.replace(/\s+/g, '_');
            navigator.clipboard.writeText(`{${formattedTitle}}`).then(() => {
              showNotification(`Copied {${formattedTitle}} to clipboard`);
            });
          } else {
            showNotification('Title is empty. Please enter a title to copy.');
          }
        });

        paramDiv.querySelector('.delete-parameter').addEventListener('click', () => {
          deleteParameter(paramDiv);
        });

        parametersRef.current.appendChild(paramDiv);
        updateParameterType(paramDiv);

        // Populate parameter values
        switch (param.type) {
          case 'textbox':
            paramDiv.querySelector('.param-title').value = param.title;
            paramDiv.querySelector('.param-placeholder').value = param.placeholder;
            break;
          case 'checkbox':
            paramDiv.querySelector('.param-title').value = param.title;
            paramDiv.querySelector('.param-options').value = param.options.join(',');
            break;
          case 'slider':
            paramDiv.querySelector('.param-title').value = param.title;
            paramDiv.querySelector('.param-min').value = param.range.min;
            paramDiv.querySelector('.param-max').value = param.range.max;
            paramDiv.querySelector('.param-label-left').value = param.labels.left;
            paramDiv.querySelector('.param-label-right').value = param.labels.right;
            break;
          case 'dropdown':
            paramDiv.querySelector('.param-title').value = param.title;
            paramDiv.querySelector('.param-options').value = param.options.join(',');
            break;
        }
      });
    } else {
      console.log('Parameters are empty or parametersRef is not initialized');
    }
  }, [filter.parameters]);

  return (
    <FilterForm
      filter={filter}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      parametersRef={parametersRef}
      error={error}
    />
  );
}

export default EditFilter;
