// src/components/shared/Parameters.js

export const addParameter = (parametersRef, showNotification, defaultType = 'textbox') => {
    const paramDiv = document.createElement('div');
    paramDiv.classList.add('parameter');
  
    paramDiv.innerHTML = `
      <label>Parameter Type:</label>
      <select>
        <option value="textbox">Textbox</option>
        <option value="checkbox">Checkbox</option>
        <option value="slider">Slider</option>
        <option value="dropdown">Dropdown</option>
      </select>
      <div class="parameterInputs"></div>
      <div class="parameter-buttons">
        <button type="button" class="copy-parameter">Copy Title</button>
        <button type="button" class="delete-parameter">Delete Parameter</button>
      </div>
    `;
  
    paramDiv.querySelector('select').value = defaultType;
    paramDiv.querySelector('select').addEventListener('change', () => {
      updateParameterType(paramDiv);
    });
  
    paramDiv.querySelector('.copy-parameter').addEventListener('click', () => {
      const title = paramDiv.querySelector('.param-title').value;
      if (title) {
        const formattedTitle = title.replace(/\s+/g, '_');
        navigator.clipboard.writeText(`{${formattedTitle}}`).then(() => {
          showNotification(`Copied {${formattedTitle}} to clipboard`);
        });
      } else {
        showNotification('Title is empty. Please enter a title to copy.');
      }
    });
  
    paramDiv.querySelector('.delete-parameter').addEventListener('click', () => {
      deleteParameter(paramDiv);
    });
  
    parametersRef.current.appendChild(paramDiv);
    updateParameterType(paramDiv);
  };
  
  export const deleteParameter = (paramDiv) => {
    paramDiv.remove();
  };
  
  export const updateParameterType = (parameterDiv) => {
    const type = parameterDiv.querySelector('select').value;
    const inputsContainer = parameterDiv.querySelector('.parameterInputs');
    inputsContainer.innerHTML = '';
  
    switch (type) {
      case 'textbox':
        inputsContainer.innerHTML += `
          <label>Title:</label>
          <input type="text" class="param-title large-input" placeholder="Enter Style (e.g., 3D Animation)">
          <label>Placeholder:</label>
          <input type="text" class="param-placeholder large-input" placeholder="Claymation">
        `;
        break;
      case 'checkbox':
        inputsContainer.innerHTML += `
          <label>Title:</label>
          <input type="text" class="param-title large-input" placeholder="Gender">
          <label>Options (comma-separated):</label>
          <input type="text" class="param-options large-input" placeholder="Heroine,Hero">
        `;
        break;
      case 'slider':
        inputsContainer.innerHTML += `
          <label>Title:</label>
          <input type="text" class="param-title large-input" placeholder="Mood Setting">
          <label>Min:</label>
          <input type="number" class="param-min large-input" placeholder="0.0" step="0.1">
          <label>Max:</label>
          <input type="number" class="param-max large-input" placeholder="1.0" step="0.1">
          <label>Left Label:</label>
          <input type="text" class="param-label-left large-input" placeholder="Somber">
          <label>Right Label:</label>
          <input type="text" class="param-label-right large-input" placeholder="Cheerful">
        `;
        break;
      case 'dropdown':
        inputsContainer.innerHTML += `
          <label>Title:</label>
          <input type="text" class="param-title large-input" placeholder="Character Role">
          <label>Options (comma-separated):</label>
          <input type="text" class="param-options large-input" placeholder="Villain,Sidekick,Mentor">
        `;
        break;
    }
  };
  
  export const getParameters = (parametersRef) => {
    const params = [];
    parametersRef.current.querySelectorAll('.parameter').forEach(paramDiv => {
      const type = paramDiv.querySelector('select').value;
      const title = paramDiv.querySelector('.param-title').value;
      let parameter = { type, title };
  
      switch (type) {
        case 'textbox':
          parameter.placeholder = paramDiv.querySelector('.param-placeholder').value;
          break;
        case 'checkbox':
          parameter.options = paramDiv.querySelector('.param-options').value.split(',');
          break;
        case 'slider':
          parameter.range = {
            min: parseFloat(paramDiv.querySelector('.param-min').value),
            max: parseFloat(paramDiv.querySelector('.param-max').value),
          };
          parameter.labels = {
            left: paramDiv.querySelector('.param-label-left').value,
            right: paramDiv.querySelector('.param-label-right').value,
          };
          break;
        case 'dropdown':
          parameter.options = paramDiv.querySelector('.param-options').value.split(',');
          break;
      }
      params.push(parameter);
    });
    return params;
  };
  
  export const validateParameters = (parameters, promptTemplate) => {
    // Check for duplicate titles
    const titles = parameters.map(param => param.title.replace(/\s+/g, '_'));
    const uniqueTitles = new Set(titles);
    if (titles.length !== uniqueTitles.size) {
      alert("Parameter titles must be unique.");
      return false;
    }
  
    // Extract placeholders from prompt_template and validate against titles
    const placeholders = [...promptTemplate.matchAll(/\{([^\}]+)\}/g)].map(match => match[1]);
  
    // Ensure every placeholder has a corresponding title and vice versa
    if (placeholders.length !== uniqueTitles.size || !placeholders.every(ph => uniqueTitles.has(ph))) {
      alert("Each prompt template placeholder must match exactly one parameter title, and each title must have a representation in the prompt template.");
      return false;
    }
  
    return true; // Passes validation
  };
  