import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import EditFilter from './components/EditFilter/EditFilter';
import CreateFilter from './components/CreateFilter/CreateFilter';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app">
        <nav>
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/edit/:id" element={<EditFilter />} />
          <Route path="/create" element={<CreateFilter />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
