// src/components/Dashboard/Dashboard.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFilters, deleteFilter } from '../../services/api';
import './Dashboard.css';

function Dashboard() {
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchFilters();
  }, []);

  const fetchFilters = async () => {
    try {
      const data = await getFilters();
      if (Array.isArray(data)) {
        setFilters(data); // Data is now directly the array of filters
      } else {
        setError('Error: Data is not an array');
      }
    } catch (err) {
      setError(`Error fetching filters. Please try again. ${err.message}`);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this filter?')) {
      try {
        await deleteFilter(id);
        fetchFilters();
      } catch (err) {
        setError(`Error deleting filter. Please try again. ${err.message}`);
      }
    }
  };

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      {error && <p className="error-message">{error}</p>}
      <ul>
        {filters.map((filter) => (
          <li key={filter._id}>
            <Link to={`/edit/${filter._id}`}>{filter.filter}</Link>
            <button onClick={() => handleDelete(filter._id)}>Delete</button>
          </li>
        ))}
      </ul>
      <Link to="/create">Create Filter</Link>
    </div>
  );
}

export default Dashboard;
