import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createFilter } from '../../services/api';
import FilterForm from '../shared/FilterForm';
import { getParameters, validateParameters } from '../shared/Parameters';
import './CreateFilter.css';

function CreateFilter() {
  const [filter, setFilter] = useState({
    model: '',
    version: '',
    cover_img: '',
    prompt_template: '',
    neg_prompt: '',
    filter: '',
    parameters: [],
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const parametersRef = useRef(null);

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!parametersRef.current) {
      setError('No parameters defined.');
      return;
    }
    const parameters = getParameters(parametersRef);
    if (!validateParameters(parameters, filter.prompt_template)) {
      setError('Validation failed. Please check the parameters.');
      return;
    }
    try {
      await createFilter({ ...filter, parameters });
      navigate('/');
    } catch (err) {
      setError('Error creating filter. Please try again.');
    }
  };

  return (
    <FilterForm
      filter={filter}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      parametersRef={parametersRef}
      error={error}
    />
  );
}

export default CreateFilter;
